export const BrandName = "Shutterlux"; // demo

export const CfgMap = {
  demo: {
    dbClusterId: "ibi2llw781",
  },
  shutterlux: {
    dbClusterId: "12pqq5anfh",
  },
};

export const env = "prd";
export const Cfg = CfgMap[BrandName.toLowerCase()];
